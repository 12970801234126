<template>
    <div v-show="props.compType === 'kitsSpecialLeave'">
        <div class="doc">
            <img
                class="transfer-icon"
                :src="transferIcon"
                alt="调休图标"
            />
            <p class="title">特殊请假套件</p>
        </div>
        <div class="leave-type">
            <span class="type">当前请假类型包括:</span>
            <span
                class="edit"
                @click="edit"
                >修改</span
            >
        </div>
        <el-radio-group  v-model="type" size="small" @input="changeType" style="margin-bottom: 10px;">
            <el-radio-button label="2">学生</el-radio-button>
            <el-radio-button label="1">员工</el-radio-button>
        </el-radio-group>
        <el-table
            v-loading="tableLoad"
            :data="tableData"
            :show-header="false"
            max-height="250"
            border
        >
            <el-table-column
                prop="vacationName"
                width="128"
            ></el-table-column>
            <el-table-column
                prop="leaveUnitName"
                width="128"
            ></el-table-column>
        </el-table>
        <div class="aggregate-table">
            系统将根据考勤规则自动计算请假时长，精确汇总至考勤汇总表
        </div>
        <div class="associated-equipment-wrapper">
            <div class="associated-equipment">
                <el-switch
                    v-model="props.isInSetting"
                    active-color="#3C7FFF"
                    inactive-color="#CCCCCC"
                    :active-value="1"
                    :inactive-value="0"
                    @change="handlerChangeAssociatedEquipment"
                ></el-switch>
                <span class="label">关联门禁</span>
            </div>
            <div class="associated-equipment-des">
                <p>（1）开启状态，请假时间内，自动与门禁关联，可出、入各一次</p>
                <p>（2）关闭状态，代表请假与出入无关，还需单独申请出入</p>
            </div>
        </div>
        <div class="reason-leave-required">
            <el-switch
                v-model="props.reasonLeaveRequired"
                active-color="#3C7FFF"
                inactive-color="#CCCCCC"
                :active-value="1"
                :inactive-value="0"
                @change="handlerChangeReasonLeaveRequired"
            ></el-switch>
            <span class="label">请假事由必填</span>
        </div>
        <div class="reason-leave-limit">
            <el-switch
                v-model="props.showLimit"
                active-color="#3C7FFF"
                inactive-color="#CCCCCC"
                :active-value="1"
                :inactive-value="0"
            />
            <span class="label">请假时长限制<span class="sub-label">（仅针对学生请假）</span></span>
        </div>
        <div class="reason-leave-limit">
            <span class="label">请假时长不低于</span>
            <el-input
                v-model="props.limitDuration"
                @blur="handleBlur"
            />
            <span class="label">小时</span>
        </div>
    </div>
</template>
<script>
import {CoworkingCampusOAVacationManagementModel} from "@/models/CoworkingCampusOAVacationManagement.js";
import {mapState} from "vuex";
export default {
    name: "kitsSpecialLeave",
    props: ["props", "getFormId"],
    data() {
        return {
            tableLoad: true,
            tableData: [],
            // 假期管理
            editRouterName: "CoworkingCampusOAVacationManagement",
            //固定时间正在编辑状态
            valueFormat:'HH:mm',
            value: '',
            defaultTime: new Date(),
            type: '2'
        };
    },
    created() {
        this.init();
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            originRouters: (state) => state.originRouters,
        }),
        transferIcon() {
            return require("@/assets/images/transfer-icon.png");
        },
    },
    methods: {
        init() {
            this.initPageData();
        },
        initPageData() {
            this.getList();

        },
        changeType(e){
            this.type = e
            this.getList()
        },
        /**
         * @Description: 获取请假类型汇总
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-04 10:14:10
         */
        getList() {
            const coworkingCampusOAVacationManagementModel =
                new CoworkingCampusOAVacationManagementModel();
            coworkingCampusOAVacationManagementModel
                .getUndeletedList({schoolId: this.schoolId, deleteStatus: "0", type: this.type,status: "1",})
                .then((res) => {
                    if (res.data.code == "200") {
                        // 特殊请假的请假类型只允许选择以小时计算且不是调休的
                        res.data.data = res.data.data.filter(e => e.leaveUnit === 2 && e.vacationName.indexOf("调休") == -1 && e.balanceSwitch != 1)
                        const data = res.data.data;
                        data.forEach((item) => {
                            let leaveUnitName =
                                    item.leaveUnit == "1" ? "按天" : "按小时",
                                durationCalculationName =
                                    item.durationCalculation == "1"
                                        ? "工作日"
                                        : "自然日";
                            this.$set(item, "leaveUnitName", leaveUnitName);
                            this.$set(
                                item,
                                "durationCalculationName",
                                durationCalculationName,
                            );
                        });
                        this.tableData = data;
                        this.tableLoad = false;
                    }
                });
        },
        /**
         * @Description: 跳转至编辑页面
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-04-04 10:14:35
         */
        edit() {
            let idx = this.originRouters.findIndex((item) => {
                return item.name == this.editRouterName;
            });
            if (idx !== -1) {
                this.$confirm("若无暂存表单信息，数据将丢失。", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        // 确认
                        console.log(idx, this.originRouters, "originRouters");
                        this.$router.push({
                            name: this.editRouterName,
                        });
                    })
                    .catch(() => {
                        // 取消
                    });
            }
        },
        /**
         * @Description: 修改关联门禁
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-04 16:20:44
         */
        handlerChangeAssociatedEquipment() {
            this.$eventDispatch(
                "handlerChangeAssociatedEquipment",
                this.props.isInSetting,
            );
        },
        /**
         * @Description:
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2023-06-13 09:18:28
         */
        handlerChangeReasonLeaveRequired () {
            this.$eventDispatch(
                "handlerChangeReasonLeaveRequired",
                this.props.reasonLeaveRequired,
            );
        },
        handleBlur() {
            let value = this.props.limitDuration.toString();
            let regex = /^(?:0*(?:[1-9]\d*|0)\.\d+|[1-9]\d*(?:\.\d+)?|0)$/;
            if(value === "0" || !regex.test(value)) {
                this.props.limitDuration = 2
            }
        }
    },
    mounted() {
    },
    directives: {
        clickout:{

            bind:(el, binding, vnode) => {
                let documentHandler = (e) => {
                    if (el.contains(e.target)) {
                        return false;
                    }
                    if (binding.expression) {
                        binding.value()
                        return false;
                    }
                }
                el.__vueClickOutside__ = documentHandler;
                document.addEventListener("click", documentHandler);
            },
            unbind(el, binding) {
                // 解除事件监听
                document.removeEventListener("click", el.__vueClickOutside__);
                delete el.__vueClickOutside__;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.doc {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
    }
    .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}
.leave-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .type {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6d7073;
    }
    .edit {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
        cursor: pointer;
    }
}
.aggregate-table {
    margin-top: 12px;
    box-sizing: border-box;
    padding: 12px;
    background-color: #f7f7f7;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #919599;
    border-radius: 4px;
}
.associated-equipment-wrapper {
    margin-top: 12px;
    .associated-equipment {
        display: flex;
        align-items: center;
        .label {
            font-size: 14px;
            margin-left: 10px;
            color: #333333;
        }
    }
    .associated-equipment-des {
        box-sizing: border-box;
        padding: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #919599;
        border-radius: 4px;
    }
}
.reason-leave-required {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .label {
        font-size: 14px;
        margin-left: 10px;
        color: #333333;
    }
}

.reason-leave-limit {
    margin-bottom: 8px;

    .label {
        font-size: 14px;
        color: #333333;

        .sub-label {
            color: #919599;
        }
    }

    .el-switch {
        margin-right: 8px;
    }

    .el-input {
        width: 80px;
        margin: 0 4px;
    }
}

</style>
